<template>
    <div class="memo-slot">
        <router-link tag="figure" :to="{ name: 'memod-reader', params: { writer: item.writer.displayname, slug: item.slug, id: Number(item.id), partNumber: 1 }}">
            <img
                v-if="coverImage"
                :srcset="`${coverImage.x1}, ${coverImage.x2} 2x, ${coverImage.x3} 3x`"
                loading="lazy"
                :alt="item.title"
            >
            <img
                v-else
                class="image landscape"
                src="@assets/img/memo-placeholder.jpg"
                loading="lazy"
                :alt="item.title"
            >
        </router-link>
        <div class="card-container">
            <router-link
                v-if="item.writer"
                :to="{ name: 'web-app-profile', params: { displayName: `${item.writer.displayname}` } }"
                class="author"
            >
                <user-picture :picture="item.writer.photo.url" />
                <user-nickname :user-nickname="item.writer.displayname" />
            </router-link>
            <router-link tag="h1" :to="{ name: 'memod-reader', params: { writer: item.writer.displayname, slug: item.slug, id: Number(item.id), partNumber: 1 }}">
                {{ item.title.substring(0, 40) }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "MemoSlot",
    components: {
        UserPicture: () => import(/* webpackChunkName: "user-picture" */ "@c/atoms/user-picture"),
        UserNickname: () => import(/* webpackChunkName: "user-name" */ "@c/atoms/user-nickname")
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            imageResizerUrl: process.env.VUE_APP_IMAGE_RESIZER_URL
        };
    },
    computed: {
        coverImage() {
            const coverImage = this.item.files.find(file => file.field_name == "cover-image");

            if (!coverImage) {
                return false;
            }

            const imagesSizes = {
                x1: "",
                x2: "",
                x3: ""
            }

            Object.keys(imagesSizes).forEach(key => {
                imagesSizes[key] = `${this.imageResizerUrl}/${coverImage ? coverImage.attributes.unique_name : ""}?w=${276 * key.charAt(key.length - 1)}&fit=outside`
            })

            return {
                ...imagesSizes,
                orientation: coverImage.attributes.orientation,
                width: Number(coverImage.attributes.width),
                height: Number(coverImage.attributes.height)
            };
        }
    }
}
</script>

<style lang="scss" scoped>
.memo-slot {
    width: 145px;
    height: 278px;
    background-color: #2C2C2E;
    border: 1px solid rgba(84, 84, 88, 0.65);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    figure {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        overflow: hidden;
        flex: 1;

        img {
            height: 100%;
        }
    }

    .card-container {
        padding: 10px;

        .author {
            display: flex !important;
            align-items: center;
            color: white;
            text-decoration: none;
            margin-bottom: 5px;

            /deep/ .user-picture {
                figure {
                    width: 20px;
                    height: 20px;
                }

                margin-right: 5px;
            }

            /deep/ .user-nickname {
                color: #D7D9DB;
                font-size: 10px;
            }
        }

        h1 {
            font-weight: normal;
            font-size: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}
</style>
