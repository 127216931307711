<template>
    <div class="carousel widget">
        <h1 class="title">
            {{ title }}
        </h1>

        <div class="widget-container">
            <div class="sub-title-container" @click="onTitlePressed">
                <h2 class="sub-title">
                    {{ subTitle }}
                </h2>
                <div class="back-button">
                    <img src="@assets/img/icons/ic-arrow-foward.svg">
                </div>
            </div>
            <swiper
                ref="carouselWidget"
                :options="swiperOptions"
            >
                <swiper-slide v-for="(item, index) in items" :key="index">
                    <div class="carousel-card" @click="onCardPressed(item)">
                        <component
                            :is="componentAs"
                            :item="item"
                        />
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import MemoSlot from "../components/memo-slot.vue";
import BoardSlot from "../components/board-slot.vue";

export default {
    name: "CarouselWidget",
    components: {
        Swiper,
        SwiperSlide,
        MemoSlot,
        BoardSlot
    },
    props: {
        title: {
            type: String,
            default: ""
        },
        subTitle: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        componentAs: {
            type: String,
            required: true
        },
        notableReference: {
            type: String,
            required: true
        },
        widgetType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            swiperOptions: {
                slidesPerView: 3,
                width: 480,
                slidesOffsetAfter: 150
            }
        }
    },
    methods: {
        onTitlePressed() {
            return this.$router.push({
                name: "web-app-notable-details",
                params: { uuid: this.notableReference, type: this.widgetType, title: this.title }
            });
        },
        onCardPressed(widget) {
            switch (this.widgetType) {
                case "Boards":
                    return this.$router.push({
                        name: "web-app-board-view",
                        params: {
                            displayName: widget.user.displayname,
                            id: Number(widget.id)
                        }
                    });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.carousel {
    .title {
        font-weight: 600;
        font-size: 26px;
        margin-bottom: 15px;
        margin-top: 30px;

    }

    .widget-container {
        width: 350px;
        background-color: #1C1C1E;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        padding: 12px;
        height: 362px;

        .sub-title {
            font-weight: normal;
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 25px;
            margin-top: 10px;
            cursor: pointer;
        }

        .people-card {
            width: initial;
            padding: 0;

            /deep/ .people-header {
                .follow-button {
                    .btn {
                        width: 88px;
                    }
                }
            }
        }

        .people-card:last-child {
            margin-bottom: 0;
        }

        .topic-slot:last-child {
            margin-bottom: 0;
        }

        .sub-title-container {
            display: flex;
            justify-content: space-between;
            .back-button {
                width: 40px;
                height: 40px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #1C1C1E !important;

                img {
                    width: 40px;
                    height: 40px;
                    background-color: #1C1C1E !important;
                }
            }
        }

        .carousel-card {
            cursor: pointer;
        }
    }
}
</style>
