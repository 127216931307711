<template>
    <div class="board-slot">
        <figure>
            <img
                :src="item.image_url"
                loading="lazy"
                :alt="item.title"
            >
        </figure>
        <div class="card-container">
            <router-link
                v-if="item.user"
                :to="{ name: 'web-app-profile', params: { displayName: `${item.user.displayname}` } }"
                class="author"
            >
                <user-picture :picture="item.user.photo.url" />
                <user-nickname :user-nickname="item.user.displayname" />
            </router-link>
            <h1>{{ item.list_name.substring(0, 40) }}</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "BoardSlot",
    components: {
        UserPicture: () => import(/* webpackChunkName: "user-picture" */ "@c/atoms/user-picture"),
        UserNickname: () => import(/* webpackChunkName: "user-name" */ "@c/atoms/user-nickname")
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.board-slot {
    width: 145px;
    height: 278px;
    background-color: #2C2C2E;
    border: 1px solid rgba(84, 84, 88, 0.65);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    figure {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        overflow: hidden;
        flex: 1;

        img {
            height: 100%;
        }
    }

    .card-container {
        padding: 10px;

        .author {
            display: flex !important;
            align-items: center;
            color: white;
            text-decoration: none;
            margin-bottom: 5px;

            /deep/ .user-picture {
                figure {
                    width: 20px;
                    height: 20px;
                }

                margin-right: 5px;
            }

            /deep/ .user-nickname {
                color: #D7D9DB;
                font-size: 10px;
            }
        }

        h1 {
            font-weight: normal;
            font-size: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}
</style>
